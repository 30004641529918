$sizes: 1rem 2rem 4rem 6rem 10rem 15rem 18rem 20rem 30rem;

@each $size in $sizes {
	.w-#{$size} {
		width: $size;
	}
}

.ms-25 {
	margin-left: 6.25rem !important;
}

.ms-40 {
	margin-left: 10.00rem !important;
}

.ms-55 {
	margin-left: 16.25rem !important;
}

.kt_app_wrapper_without_sidebar {
	margin-left: 0px;
}

.width-100p {
	width: 100%;
}

.width-80p {
	width: 80%;
}

.width-20px {
	width: 20px;
}

.bigdrop .select2-results .select2-results__options {
	max-height: 600px !important;
}

.swal2-error-list-body {
	max-height: 400px !important;
}

.react-autosuggest__input {
	display: block;
	font-family: $input-font-family;
	@include font-size($input-font-size);
	font-weight: $input-font-weight;
	line-height: $input-line-height;
	color: $input-color;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;
	appearance: none; // Fix appearance for date inputs in Safari
	// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
	@include border-radius($input-border-radius, 0);

	@include box-shadow($input-box-shadow);
	@include transition($input-transition);

	&[type="file"] {
		overflow: hidden; // prevent pseudo element button overlap

		&:not(:disabled):not([readonly]) {
			cursor: pointer;
		}
	}
	// Customize the `:focus` state to imitate native WebKit styles.
	&:focus {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;

		@if $enable-shadows {
			@include box-shadow($input-box-shadow, $input-focus-box-shadow);
		}
		@else {
			// Avoid using mixin so we can pass custom focus shadow properly
			box-shadow: $input-focus-box-shadow;
		}
	}
	// Add some height to date inputs on iOS
	// https://github.com/twbs/bootstrap/issues/23307
	// TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
	&::-webkit-date-and-time-value {
		// Multiply line-height by 1em if it has no unit
		height: if(unit($input-line-height) == "", $input-line-height * 1em, $input-line-height);
	}
	// Prevent excessive date input height in Webkit
	// https://github.com/twbs/bootstrap/issues/34433
	&::-webkit-datetime-edit {
		display: block;
		padding: 0;
	}
	// Placeholder
	&::placeholder {
		color: $input-placeholder-color;
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}
	// Disabled inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled {
		color: $input-disabled-color;
		background-color: $input-disabled-bg;
		border-color: $input-disabled-border-color;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}
	// File input buttons theming
	&::file-selector-button {
		padding: $input-padding-y $input-padding-x;
		margin: (-$input-padding-y) (-$input-padding-x);
		margin-inline-end: $input-padding-x;
		color: $form-file-button-color;
		@include gradient-bg($form-file-button-bg);
		pointer-events: none;
		border-color: inherit;
		border-style: solid;
		border-width: 0;
		border-inline-end-width: $input-border-width;
		border-radius: 0; // stylelint-disable-line property-disallowed-list
		@include transition($btn-transition);
	}

	&:hover:not(:disabled):not([readonly])::file-selector-button {
		background-color: $form-file-button-hover-bg;
	}

	background-color: $input-solid-bg;
	border-color: $input-solid-bg;
	color: $input-solid-color;
	@include placeholder($input-solid-placeholder-color);
	transition: $transition-input;
	min-height: $input-height-lg;
	padding: $input-padding-y-lg $input-padding-x-lg;
	@include font-size($input-font-size-lg);
	@include border-radius($input-border-radius-lg);

	&::file-selector-button {
		padding: $input-padding-y-lg $input-padding-x-lg;
		margin: (-$input-padding-y-lg) (-$input-padding-x-lg);
		margin-inline-end: $input-padding-x-lg;
	}

	.dropdown.show > &,
	&:active,
	&.active,
	&:focus,
	&.focus {
		background-color: $input-solid-bg-focus;
		border-color: $input-solid-bg-focus;
		color: $input-solid-color;
		transition: $transition-input;
	}

	width: 100%;
}

.react-autosuggest__suggestion {
	font-family: $input-font-family;
	@include font-size($input-font-size);
	font-weight: $input-font-weight;
	line-height: $input-line-height;
}

.text-bg-warning-contract {
	background-color: #8f6f00
}

.badge-light-success-2 {
	color: #FFD351
}

.badge-light-warning-2 {
	color: #FFD351
}

.skip-to-main-content {
	clip: rect(0,0,0,0);
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}

.skip-to-main-content:active, .skip-to-main-content:focus {
	clip: auto;
	height: auto;
	overflow: visible;
	position: static;
	width: auto;
	padding-left: 1rem;
	padding-right: 1rem;
}

.skip-to-main-content2 {
	clip: auto;
	height: auto;
	overflow: visible;
	position: static;
	width: auto;
	padding-left: 1rem;
	padding-right: 1rem;
}


/*
.bigdrop.select2-container .select2-results {
	max-height: 800px;
	height: 800px;
}

.bigdrop .select2-results {
	max-height: 800px;
	height: 800px;
}

.bigdrop .select2-results .select2-results__options {
	max-height: 1000px !important;
	height: 1000px !important;
}

.bigdrop .select2-choices {
	height: 750px;
	min-height: 750px;
	max-height: 750px;
	overflow-y: auto;
}
*/
@media (max-width: 1500px) {
	.col-form-label {
		font-size: 0.85rem !important;
	}
}

@media (max-width: 1300px) {
	.col-form-label {
		font-size: 0.7rem !important;
	}
}

@media (max-width: 1500px) {
	.survey-general-toolbar-button {
		padding-left: 4px !important;
		padding-right: 4px !important;
	}
}



/*@media (max-width: 1500px) {
	.star-rating-font-size {
		font-size: 0.95rem !important;
	}
}*/


.footer-menu-item {
	padding-top: 2px;
	padding-bottom: 2px;
}

.white-space-pre-wrap {
	white-space:pre-wrap;
}

.dashboard-h3 {
	margin-bottom:0px!important;
	align-items:normal!important;
}
//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
//$primary: #009ef7;
$primary: #006EAD; //Issue 13.- 1.4.3 Contrast (Minimum) [Level AA]
$primary-active: #0079BD; //Issue 13.- 1.4.3 Contrast (Minimum) [Level AA]
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
//$success: #50cd89;
$success: #26834F; //Issue 13.- 1.4.3 Contrast (Minimum) [Level AA]
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
//$danger: #f1416c;
$danger: #e91146;	//Issue 13.- 1.4.3 Contrast (Minimum) [Level AA]
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;


// Bootstrap grey colors (ovverride)
$gray-100: #f9f9f9 !default;
$gray-200: #f4f4f4 !default;
$gray-300: #e1e3ea !default;
$gray-400: #b5b5c3 !default;
$gray-500: #a1a5b7 !default;
$gray-600: #71758E !default;	//change
$gray-700: #5e6278 !default;
$gray-800: #3f4254 !default;
$gray-900: #181c32 !default;

$pagination-focus-outline: 1 !default;

$light-inverse: #696ea5 !default;
$text-muted: #6B708A !default;
$form-check-input-bg-solid: #697396 !default;
$form-check-label-color: #6f7590 !default;


